import React from 'react';
import styled from 'styled-components';

import { StarsIcon } from '~/atomic/atom/icon/StartsIcon';
import { AbonnementPageLink } from '~/atomic/atom/links/staticLinks';
import { TextBoldStyle } from '~/atomic/Typography';
import { isHaveUserAbonnementSelector, userSelector } from '~/feature/user/user.selector';
import { getAbonnementTypeName } from '~/graphql/books/factory/abonement/AbonementFactory';
import { useAppSelector } from '~/store';

export const HeaderAbonnement = () => {
  const { user } = useAppSelector(userSelector);
  const isHaveUserAbonnement = useAppSelector(isHaveUserAbonnementSelector);

  if (!isHaveUserAbonnement) {
    return (
      <SCWrapper>
        <AbonnementPageLink>
          <SCAbonnementLink>
            <SCStarsIcon />
            Абонемент
          </SCAbonnementLink>
        </AbonnementPageLink>
      </SCWrapper>
    );
  }

  return (
    <SCWrapper data-is-active>
      <AbonnementPageLink>
        <SCAbonnementLink>
          <SCStarsIcon />
          Абонемент подключён
        </SCAbonnementLink>
      </AbonnementPageLink>
    </SCWrapper>
  );
};

const SCAbonnementLink = styled.a`
  position: relative;
  ${TextBoldStyle};
  color: var(--black-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCStarsIcon = styled(StarsIcon)`
  font-size: 20px;
  margin-right: 8px;
`;

const SCWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  
  &[data-is-active="true"] {
    ${SCAbonnementLink} {
      color: #4B96FB;
    }
  }
`;
